<template>
    <div class="door_device-content">
        <div class="door_deviec-tabler">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <!--                <h3 style="margin-left: 20px">分组</h3>-->
                <el-select style="margin-left: 20px" v-model="deviceGroupValue" placeholder="请选择"
                           @change="selecDeviceGroupChange">
                    <el-option v-for="item in deviceGroupData" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>

            <el-input v-model="likeModel" placeholder="请输入名称进行模糊搜索" style="width: 200px;margin-left: 20px"
                      @input="likeInput"></el-input>

            <el-input v-model="likeSnModel" placeholder="请输入序列号进行模糊搜索" style="width: 200px;margin-left: 20px"
                      @input="likeSnInput"></el-input>

            <el-button size="mini" v-if="selectDeviceArray.length<1" type="primary" style="margin-left: 50px"
                       @click="addDevieGroupDialogVisible = true">增加分组
            </el-button>

            <el-button size="mini" v-if="deviceGroupData.length>1?selectDeviceArray.length<1:false" type="danger"
                       style="margin-left: 50px" @click="deleteDeviceGroup">删除分组
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length<1" type="primary" style="margin-left: 50px"
                       @click="updateDevieGroupDialogVisible = true">修改分组名称
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length<1" type="primary" style="margin-left: 50px"
                       @click="addDeviceDialogVisible = true">增加设备
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length > 0" type="danger" style="margin-left: 50px"
                       @click="deleteSelectDevice">删除设备
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length > 0" type="warning" style="margin-left: 50px"
                       @click="selectUpdateDevieGroupDialogVisible =true">修改设备分组
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length > 0" type="primary" style="margin-left: 50px"
                       @click="openPlushPerson">下发人员
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length > 0" type="warning" style="margin-left: 50px"
                       @click="selectDeviceReboot">重启
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length > 0" type="danger" style="margin-left: 50px"
                       @click="selectDeviceShutdown">关机
            </el-button>

            <el-button size="mini" v-if="selectDeviceArray.length<1" type="primary" style="margin-left: 50px"
                       @click="refieshDevice">刷新
            </el-button>
        </div>

        <div class="door_device-data-content">
            <el-table ref="filterTable" :data="deviceData" height="100%" style="width: 100%;" v-loading="loading"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" show-overflow-tooltip align="center" prop="id" width="50">
                </el-table-column>
                <el-table-column
                        label="序号"
                        align="center"
                        type="index"
                        width="100">
                </el-table-column>
                <el-table-column align="center" prop="name" label="名称" width="200">
                </el-table-column>
                <el-table-column align="center" prop="serialNumber" label="序列号" width="200">
                </el-table-column>

                <el-table-column align="center" label="其它" width="100">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>序列号: {{ scope.row.serialNumber }}</p>
                            <p>密码: {{ scope.row.password }}</p>
                            <p>IP地址: {{ scope.row.ip }}</p>
                            <p>描述: {{ scope.row.description}}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="state" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state == 1 ? 'success' : 'danger'" disable-transitions>
                            {{ getDeviceState(scope.row.state) }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="description" label="描述" width="200">
                </el-table-column>

                <el-table-column align="center" label="操作" width="auto">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleVoiceBroadcast(scope.$index, scope.row)">
                            语音播报
                        </el-button>
                        <el-button size="mini" type="success" @click="handleOpenBox(scope.$index, scope.row)">开箱
                        </el-button>
                        <el-button size="mini" type="warning" @click="handleRestart(scope.$index, scope.row)">重启
                        </el-button>
                        <el-button size="mini" type="danger" @click="handleShutdown(scope.$index, scope.row)">关机
                        </el-button>
                        <el-button size="mini" type="primary" @click="handleLook(scope.$index, scope.row)">查看
                        </el-button>
                        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">修改
                        </el-button>
                        <el-button size="mini" type="infor" @click="handleOther(scope.$index, scope.row)">设置
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="deviceCount">
            </el-pagination>
        </div>

        <el-dialog title="增加设备组" :visible.sync="addDevieGroupDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogAddDeviceGroupName" maxlength="20" type="text"
                          show-word-limit>
				</el-input>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addDeviceGroup">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="修改设备组" :visible.sync="updateDevieGroupDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogUpdateDeviceGroupName" maxlength="20" type="text"
                          show-word-limit>
				</el-input>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateDeviceGroup">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="增加设备" :visible.sync="addDeviceDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogDeviceName" maxlength="20" type="text" show-word-limit>
				</el-input>
				<el-input style="margin-top: 20px" placeholder="序列号" maxlength="30" type="text" show-word-limit
                          v-model="dialogDeviceSerialNumber"></el-input>
				<el-input style="margin-top: 20px" placeholder="密码" maxlength="6" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="dialogDevicePassword"></el-input>
				<el-input style="margin-top: 20px" placeholder="描述" maxlength="30" type="text" show-word-limit
                          v-model="dialogDeviceDescription"></el-input>
				<el-select style="margin-top: 20px;width: 100%;" v-model="deviceGroupValue" placeholder="请选择"
                           @change="addDeviceGroupChange">
					<el-option v-for="item in deviceGroupData" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addDevice">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="修改设备" :visible.sync="updateDeviceDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogDeviceName" maxlength="20" type="text" show-word-limit>
				</el-input>
				<el-input style="margin-top: 20px" placeholder="序列号" maxlength="30" type="text" show-word-limit
                          disabled="false" v-model="dialogDeviceSerialNumber"></el-input>
				<el-input style="margin-top: 20px" placeholder="密码" maxlength="6" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="dialogDevicePassword"></el-input>
				<el-input style="margin-top: 20px" placeholder="描述" maxlength="30" type="text" show-word-limit
                          v-model="dialogDeviceDescription"></el-input>
				<el-select style="margin-top: 20px;width: 100%;" v-model="deviceGroupValue" placeholder="请选择"
                           @change="updateDeviceGroupChange">
					<el-option v-for="item in deviceGroupData" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateDevice">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="设置" :visible.sync="otherDeviceDialogVisible" width="50%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-card style="width: 100%; margin-top: 5px">
					<h4>识别:</h4>
					<el-switch v-model="config[0]" active-text="配置开关"></el-switch>
					<div style="display: flex;flex-direction: row;align-items: center;width: 100%;margin-top: 2px;">
						<h5>配置阈值:</h5>
						<el-slider style="width: 80%; margin-left: 20px" v-model="config[1]" max=60 show-input>
						</el-slider>
					</div>
					<div style="display: flex;flex-direction: row;align-items: center;width: 100%;margin-top: 2px;">
						<h5>识别阈值:</h5>
						<el-slider style="width: 80%; margin-left: 20px" v-model="config[2]" max=100 show-input>
						</el-slider>
					</div>
                    <el-switch v-model="config[3]" active-text="严格模式"></el-switch>
				</el-card>

				<el-button type="primary" style="width: 100%; margin-top: 20px" @click="setDeviceConfig()">确认修改
				</el-button>
			</span>
        </el-dialog>
        <el-dialog title="下发人员" :visible.sync="selectPersonPushDialogVisible" width="60%" :modal-append-to-body="false"
                   :before-close="handleClose">
            <el-progress v-if="percentage > 0" :percentage="percentage"></el-progress>
            <div style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                <el-select v-model="personGroupValue" placeholder="请选择" @change="selecPersonGroupChange">
                    <el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
                <el-button v-if="selectPersonArray.length > 0" type="primary" style="margin-left: 20px"
                           @click="plushPerson()">下发
                </el-button>
            </div>
            <div style="width: 100%; height: 500px; display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <el-table ref="filterTable" :data="personData" height="100%" style="margin-top: 10px"
                          v-loading="personLoading" @selection-change="handlePersonSelectionChange">
                    <el-table-column type="selection" align="center" width="50">
                    </el-table-column>
                    <el-table-column align="center" prop="index" label="序号" width="50">
                    </el-table-column>
                    <el-table-column align="center" prop="fileUrl" label="照片" width="200">
                        <template scope="scope">
                            <el-image style="width: 80px; height: 80px" :src="scope.row.fileUrl"
                                      @click="showImage(scope.row.fileUrl)"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column align="left" prop="name" label="姓名" width="auto">
                    </el-table-column>
                    <el-table-column align="left" prop="idCard" label="身份证" width="auto">
                    </el-table-column>
                    <el-table-column align="center" prop="icCard" label="IC卡" width="auto">
                    </el-table-column>
                    <el-table-column align="center" prop="type" label="人员类型" width="100">
                        <template slot-scope="scope">
                            <el-tag type="success" disable-transitions>
                                {{ getPersonType(scope.row.type) }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="箱号"
                            width="300">
                        <template slot-scope="scope">
                            <el-input-number v-model="personData[scope.$index].boxNumber" :min="0" :max="1000"
                                             label="箱号"></el-input-number>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;margin-top: 20px;">
                <el-pagination style="margin-top: 10px" @size-change="handlePersonSizeChange"
                               @current-change="handlePersonCurrentChange" :current-page="personPage"
                               :page-sizes="[10, 100, 500, 1000, 2000,3000]" :page-size="personLength"
                               layout="total, sizes, prev, pager, next, jumper" :total="personCount">
                </el-pagination>
            </div>
        </el-dialog>

        <el-dialog title="查看" :visible.sync="lookDeviceDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-button type="primary" @click="lookDevicePerson">查看设备人员</el-button>
            </div>
            <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;">
                <el-button type="success" @click="lookDeviceRecord">查看设备记录</el-button>
            </div>

            <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;">
                <el-button type="info" @click="lookDeviceRule">查看设备规则</el-button>
            </div>

            <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;">
                <el-button type="danger" @click="lookBoxState">查看设备箱子</el-button>
            </div>
        </el-dialog>

        <el-dialog title="查看设备人员" :visible.sync="lookDevicePersonDialogVisible" width="75%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <el-progress v-if="percentage > 0" :percentage="percentage"></el-progress>
            <div style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                <el-button v-if="selectDevicePersonArray.length > 0" type="danger" style="margin-top: 20px"
                           @click="deleteSelectDevicePerson()">删除选中人员
                </el-button>
                <el-button v-if="selectDevicePersonArray.length > 0" type="primary" style="margin-top: 20px"
                           @click="addSelectDevicePerson()">添加选中人员到当前系统
                </el-button>
            </div>

            <div style="width: 100%; height: 500px; display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <el-table ref="filterTable" :data="devicePersonData" height="500px" style="margin-top:10px"
                          v-loading="devicePersonLoading" @selection-change="handleDevicePersonSelectionChange">
                    <el-table-column type="selection" align="center" width="50">
                    </el-table-column>
                    <el-table-column align="center" prop="base64" label="照片" width="180">
                        <template scope="scope">
                            <el-image style="width: 40px; height: 40px" :src="getBase64(scope.row.base64)"
                                      @click="showImage(getBase64(scope.row.base64))">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="姓名" width="180">
                    </el-table-column>
                    <el-table-column align="center" prop="boxnum" label="箱号" width="auto">
                    </el-table-column>
                    <el-table-column align="center" prop="" label="其它" width="auto">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>身份证: {{ scope.row.idcard }}</p>
                                <p>IC卡: {{ scope.row.iccard }}</p>
                                <p>手机号: {{ scope.row.phone }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">查看</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="type" label="人员类型" width="100">
                        <template slot-scope="scope">
                            <el-tag type="success" disable-transitions>
                                {{ getPersonType(scope.row.type) }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" sortable prop="createTime" label="创建时间" width="200">
                        <template slot-scope="scope">
                            {{transformTime(scope.row.createTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button size="mini" type="warning"
                                       @click="updateDeviceSinglePerson(scope.$index, scope.row)">
                                修改
                            </el-button>
                            <el-button size="mini" type="danger"
                                       @click="deleteDeviceSinglePerson(scope.$index, scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;margin-top: 20px;">
                <el-pagination style="margin-top: 10px" @size-change="handleDevicePersonSizeChange"
                               @current-change="handleDevicePersonCurrentChange" :current-page="devicePersonPage"
                               :page-sizes="[5, 10]" :page-size="devicePersonLength"
                               layout="total, sizes, prev, pager, next, jumper" :total="devicePersonCount">
                </el-pagination>
            </div>
        </el-dialog>
        <el-dialog title="查看设备记录" :visible.sync="lookDeviceRecordDialogVisible" width="60%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <el-progress v-if="percentage > 0" :percentage="percentage"></el-progress>
            <div style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;">
                <el-button v-if="selectDeviceRecordArray.length > 0" type="danger" style="margin-top: 20px"
                           @click="deleteSelectDeviceRecord()">删除选中记录
                </el-button>
            </div>
            <div style="width: 100%; height: 500px; display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <el-table ref="filterTable" :data="deviceRecordData" style="margin-top: 10px" height="100%"
                          v-loading="deviceRecordLoading" @selection-change="handleDeviceRecordSelectionChange">
                    <el-table-column type="selection" align="center" width="50">
                    </el-table-column>
                    <el-table-column align="center" prop="base64" label="抓拍" width="100">
                        <template scope="scope">
                            <el-image style="width: 80px; height: 80px" :src="getBase64(scope.row.base64)"
                                      @click="showImage(getBase64(scope.row.base64))">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="姓名" width="100">
                    </el-table-column>
                    <el-table-column align="center" prop="persontype" label="人员类型" width="100">
                        <template slot-scope="scope">
                            <el-tag type="success" disable-transitions>
                                {{ getPersonType(scope.row.persontype) }}
                            </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" prop="verificationtype" label="验证类型" width="100">
                        <template slot-scope="scope">
                            <el-tag type="success" disable-transitions>
                                {{ getVerificationtype(scope.row.verification) }}
                            </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" prop="" label="其它" width="auto">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>身份证: {{ scope.row.idcard }}</p>
                                <p>IC卡: {{ scope.row.iccard }}</p>
                                <p>结果: {{ scope.row.result }}</p>
                                <p>分数: {{ scope.row.score }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">查看</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" sortable prop="time" label="时间" width="200">
                        <template slot-scope="scope">
                            {{transformTime(scope.row.time)}}
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger"
                                       @click="deleteDeviceSingleRecord(scope.$index, scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 100%;">
                <el-pagination style="margin-top: 10px" @size-change="handleDeviceRecordSizeChange"
                               @current-change="handleDeviceRecordCurrentChange" :current-page="deviceRecordPage"
                               :page-sizes="[5, 10]" :page-size="deviceRecordLength"
                               layout="total, sizes, prev, pager, next, jumper" :total="deviceRecordCount">
                </el-pagination>
            </div>
        </el-dialog>

        <el-dialog title="查看箱子状态" :visible.sync="lookBoxStateDialogVisible" width="75%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="width: 100%;margin-bottom: 20px">
                <el-button size="mini" type="primary" @click="getBoxData">
                    刷新
                </el-button>
                <el-button size="mini" type="primary" @click="allOpenBox">
                    一键全开
                </el-button>

                <el-button size="mini" type="primary" @click="allClearBox">
                    一键全清
                </el-button>

                <el-button size="mini" type="primary" @click="allLockBox">
                    一键全锁
                </el-button>

                <el-button size="mini" type="primary" @click="allUnLockBox">
                    一键全恢复
                </el-button>

                <el-tag type="info" style="margin-left: 50px">
                    总数:{{totality}}
                </el-tag>

                <el-tag type="danger" style="margin-left: 20px">
                    已使用:{{haveBeenUsed}}
                </el-tag>

                <el-tag type="success" style="margin-left: 20px">
                    未使用:{{unused}}
                </el-tag>


            </div>
            <el-table ref="filterTable" :data="boxData" height="500" style="width: 100%;" v-loading="boxLoading">
                <el-table-column align="center" sortable prop="boxNumber" label="箱号" width="100">
                </el-table-column>
                <!--                <el-table-column align="center" prop="type" label="类型" width="100">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-tag type="success" disable-transitions>-->
                <!--                            {{ getBoxType(scope.row.type) }}-->
                <!--                        </el-tag>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->


                <el-table-column align="center" prop="remark" label="备注" width="200">

                </el-table-column>

                <el-table-column align="center" prop="card" label="绑定卡号" width="200">

                </el-table-column>

                <el-table-column align="center" prop="state" label="占用状态" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.state == 0 ? 'success'
                                : (scope.row.state == 1?((new Date().getTime()-scope.row.time>=1296000000)?'warning':'danger'):'info')"
                                disable-transitions>
                            {{ getBoxState(scope.row.state) }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="msg" label="归还状态" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.msg.includes('已归还')" style="color: #1AA034">
                            {{scope.row.msg}}
                        </div>
                        <div v-else-if="scope.row.msg.includes('未归还')" style="color: #f60000">
                            {{scope.row.msg}}
                        </div>
                        <div v-else-if="scope.row.msg.includes('不对应')" style="color: #f60000">
                            {{scope.row.msg}}
                        </div>
                        <div v-else style="color: #f60000">
                            {{scope.row.msg}}
                        </div>

                    </template>
                </el-table-column>

                <el-table-column align="center" prop="msg" label="箱门状态" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.lock==1" style="color: #1AA034">
                            {{'已关门'}}
                        </div>
                        <div v-else-if="scope.row.lock==0" style="color: #f60000">
                            {{'未关门'}}
                        </div>
                        <div v-else style="color: #f60000">
                            {{'未知'}}
                        </div>

                    </template>
                </el-table-column>

                <el-table-column align="center" label="操作" width="450">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="dialogUpdateRemarkBox(scope.$index, scope.row)">
                            修改备注
                        </el-button>
                        <el-button size="mini" type="success"
                                   @click="dialogModifyTheBoundCardNumber(scope.$index, scope.row)">
                            修改绑定卡号
                        </el-button>
                        <el-button size="mini" type="success" @click="dialogOpenBox(scope.$index, scope.row)">
                            开箱
                        </el-button>
                        <el-button size="mini" type="warning " @click="dialogClearBox(scope.$index, scope.row)">
                            清箱
                        </el-button>
                        <el-button v-if="(scope.row.state==0)||(scope.row.state==1)" size="mini" type="info"
                                   @click="dialogLockBox(scope.$index, scope.row)">
                            锁定
                        </el-button>
                        <el-button v-if="scope.row.state==2" size="mini" type="success"
                                   @click="dialogUnLockBox(scope.$index, scope.row)">
                            解锁
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>


        <el-dialog title="查看规则" :visible.sync="lookDeviceRuleDialogVisible" width="50%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="width: 100%;margin-bottom: 20px;margin-left: 40px">
                <el-button size="mini" type="primary" @click="addDeviceRule">
                    增加
                </el-button>

                <el-button size="mini" type="primary" @click="getDeviceRuleList">
                    刷新
                </el-button>
            </div>
            <el-table ref="filterTable" :data="deviceRuleData" height="500" style="width: 100%;"
                      v-loading="deviceRuleLoading">
                <el-table-column align="center" prop="name" label="规则名称" width="150">
                </el-table-column>
                <el-table-column align="center" prop="type" label="类型" width="100">
                    <template slot-scope="scope">
                        <div>
                            {{ getDeviceRuleType(scope.row.type) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="startTime" label="开始使用时间" width="150">
                </el-table-column>
                <el-table-column align="center" prop="endTime" label="结束使用时间" width="150">
                </el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" @click="dialogEditDeviceRule(scope.$index, scope.row)">
                            编辑
                        </el-button>
                        <el-button size="mini" type="danger" @click="dialogDeleteDeviceRule(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>


        <el-dialog title="增加规则" :visible.sync="addDeviceRuleDialogVisible" width="40%" :before-close="handleClose"
                   :modal-append-to-body="false">

            <div style="display: flex;flex-direction: row;width: 100%;">
                <el-time-picker
                        v-model="dialogRuleStartTime"
                        :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"
                        placeholder="开始时间">
                </el-time-picker>

                <el-time-picker
                        style="margin-left: 40px"
                        arrow-control
                        v-model="dialogRuleEndTime"
                        :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"
                        placeholder="结束时间">
                </el-time-picker>
            </div>
            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-checkbox-group v-model="checkRuleWeekList" @change="handleDeviceRuleChecked">
                    <el-checkbox label="星期天"></el-checkbox>
                    <el-checkbox label="星期一"></el-checkbox>
                    <el-checkbox label="星期二"></el-checkbox>
                    <el-checkbox label="星期三"></el-checkbox>
                    <el-checkbox label="星期四"></el-checkbox>
                    <el-checkbox label="星期五"></el-checkbox>
                    <el-checkbox label="星期六"></el-checkbox>
                </el-checkbox-group>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-radio-group v-model="deviceRuleRadio">
                    <el-radio :label="0">设备规则</el-radio>
                    <el-radio :label="1">人员规则</el-radio>
                </el-radio-group>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-input v-model="deviceRuleName" placeholder="请输入规则名称"></el-input>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-button type="primary" @click="saveDeviceRule">保存</el-button>
            </div>
        </el-dialog>


        <el-dialog title="编辑规则" :visible.sync="editDeviceRuleDialogVisible" width="40%" :before-close="handleClose"
                   :modal-append-to-body="false">

            <div style="display: flex;flex-direction: row;width: 100%;">
                <el-time-picker
                        v-model="editDialogRuleStartTime"
                        :picker-options="{selectableRange: '00:00:00 - 23:59:00'}"
                        placeholder="开始时间">
                </el-time-picker>

                <el-time-picker
                        style="margin-left: 40px"
                        arrow-control
                        v-model="editDialogRuleEndTime"
                        :picker-options="{selectableRange: '00:00:00 - 23:59:00'}"
                        placeholder="结束时间">
                </el-time-picker>
            </div>
            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-checkbox-group v-model="editCheckRuleWeekList" @change="handleEditDeviceRuleChecked">
                    <el-checkbox label="星期天"></el-checkbox>
                    <el-checkbox label="星期一"></el-checkbox>
                    <el-checkbox label="星期二"></el-checkbox>
                    <el-checkbox label="星期三"></el-checkbox>
                    <el-checkbox label="星期四"></el-checkbox>
                    <el-checkbox label="星期五"></el-checkbox>
                    <el-checkbox label="星期六"></el-checkbox>
                </el-checkbox-group>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-radio-group v-model="editDeviceRuleRadio">
                    <el-radio :label="0">设备规则</el-radio>
                    <el-radio :label="1">人员规则</el-radio>
                </el-radio-group>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-input v-model="editDeviceRuleName" placeholder="请输入规则名称"></el-input>
            </div>

            <div style="display: flex;flex-direction: row;width: 100%;margin-top: 50px">
                <el-button type="primary" @click="editSaveDeviceRule">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择人员添加指定分组" :visible.sync="selectPersonAddToGroupDialogVisible" width="20%"
                   :before-close="handleClose" :modal-append-to-body="false">
            <el-select v-model="personGroupValue" placeholder="请选择" @change="selecPersonGroupChange2">
                <el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-button style="margin-left: 20px" type="primary" @click="selectPersonAddToGroup">确定</el-button>
        </el-dialog>

        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-image :src="dialogPersonFileUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="选择修改设备分组" :visible.sync="selectUpdateDevieGroupDialogVisible" width="20%"
                   :before-close="handleClose" :modal-append-to-body="false">
            <el-select v-model="deviceGroupValue" placeholder="请选择" @change="updateSelecDeviceGroupChange">
                <el-option v-for="item in deviceGroupData" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-button style="margin-left: 20px" type="primary" @click="updateSelectDeviceToGroup">确定</el-button>
        </el-dialog>

        <el-dialog title="修改人员" :visible.sync="updatePersonDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<div
                        style="width: 100%;margin-bottom: 20px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
					<el-upload class="avatar-uploader" :action="axios.defaults.baseURL+'/file/uploadPhoto'"
                               :show-file-list="false" :on-success="handleAvatarSuccess"
                               :before-upload="beforeAvatarUpload">
						<img style="width: 100%;height: 100%" v-if="dialogPersonFileUrl" :src="dialogPersonFileUrl"
                             class="avatar">
						<div v-else
                             style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 200px;height: 200px;">
							<el-button>添加照片</el-button>
						</div>

					</el-upload>
				</div>
				<el-input placeholder="名称" v-model="devicePersonArray[1]" maxlength="20" type="text" show-word-limit>
				</el-input>
				<el-input style="margin-top: 20px;" placeholder="身份证号" maxlength="32" type="text" show-word-limit
                          v-model="devicePersonArray[2]"></el-input>
				<el-input style="margin-top: 20px;" placeholder="IC卡号" maxlength="15" type="text" show-word-limit
                          v-model="devicePersonArray[3]"></el-input>
				<el-input style="margin-top: 20px;" placeholder="手机号" maxlength="11" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="devicePersonArray[4]"></el-input>
				<el-input style="margin-top: 20px;" placeholder="箱号" maxlength="3" type="text" show-word-limit
                          v-model="devicePersonArray[6]"></el-input>
				<el-radio-group v-model="devicePersonArray[5]" style="margin-top: 20px;">
					<el-radio :label="0">大众型</el-radio>
					<el-radio :label="1">私有型</el-radio>
					<el-radio :label="2">管理员</el-radio>
				</el-radio-group>

                <el-divider v-if="personRuleData.length>0">规则</el-divider>
                <el-checkbox-group style="margin-top: 20px" v-model="selectPersonRule"
                                   @change="handlerSelectPersonRule">
                    <div v-for="rule in personRuleData" style="display: flex;flex-direction: row;width: 100%">
                          <el-checkbox v-if="rule.type==1" :label="rule.name"></el-checkbox>
                    </div>
                </el-checkbox-group>

			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updatePerson">确 定</el-button>
			</span>
        </el-dialog>


    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                deviceGroupValue: '',
                deviceGroupData: [],
                deviceData: [],
                loading: false,
                deviceCount: 0,
                deviceGroupId: 0,
                page: 1,
                length: 10,
                updateDeviceDialogVisible: false,
                addDevieGroupDialogVisible: false,
                updateDevieGroupDialogVisible: false,
                otherDeviceDialogVisible: false,
                lookDeviceDialogVisible: false,
                lookDevicePersonDialogVisible: false,
                lookDeviceRecordDialogVisible: false,
                selectPersonAddToGroupDialogVisible: false,
                selectUpdateDevieGroupDialogVisible: false,
                updatePersonDialogVisible: false,
                lookBoxStateDialogVisible: false,
                lookDeviceRuleDialogVisible: false,
                addDeviceRuleDialogVisible: false,
                editDeviceRuleDialogVisible: false,
                dialogAddDeviceGroupName: '',
                dialogUpdateDeviceGroupName: '',
                addDeviceDialogVisible: false,
                dialogDeviceId: 0,
                dialogDeviceName: '',
                dialogDeviceSerialNumber: '',
                dialogDevicePassword: '',
                dialogDeviceDescription: '',
                selectDeviceArray: [],
                selectPersonPushDialogVisible: false,
                selectOnLineUnAddDeviceArray: [],
                onLineUnAddDeviceGroupId: 0,
                config: [3, 0, false, 0, 88, 0, 5, 3],
                device: {},
                personPage: 1,
                personLength: 10,
                personData: [],
                selectPersonArray: [],
                personCount: 0,
                personGroupData: [],
                personGroupId: 0,
                personGroupValue: '',
                personLoading: false,
                devicePersonData: [],
                devicePersonLoading: false,
                selectDevicePersonArray: [],
                devicePersonLength: 5,
                devicePersonCount: 0,
                devicePersonPage: 1,
                lookDevice: {},
                deviceRecordData: [],
                deviceRecordLoading: false,
                selectDeviceRecordArray: [],
                deviceRecordPage: 1,
                deviceRecordLength: 5,
                deviceRecordCount: 0,
                percentage: 0,
                showImageDialogVisible: false,
                dialogPersonFileUrl: '',
                devicePersonArray: [],
                boxData: [],
                totality:0,
                haveBeenUsed: 0,
                unused: 0,
                boxLoading: false,
                likeModel: null,
                likeSnModel: null,
                deviceRuleData: [],
                personRuleData: [],
                deviceRuleLoading: false,
                dialogRuleStartTime: new Date(),
                dialogRuleEndTime: new Date(),
                checkRuleWeekList: [],
                deviceRuleRadio: 0,
                deviceRuleName: '',
                editDeviceRule: {},
                editDialogRuleStartTime: null,
                editDialogRuleEndTime: null,
                editCheckRuleWeekList: [],
                editDeviceRuleRadio: 0,
                editDeviceRuleName: '',
                selectPersonRule: []
            }
        },
        mounted() {
            this.refieshDevice()
        },
        methods: {
            handleSizeChange(length) {
                this.length = length
                this.getDeviceList()

            },
            handlePersonSizeChange(personLength) {
                this.personLength = personLength
                this.getPersonList()
            },
            handleDevicePersonSizeChange(devicePersonLength) {
                this.devicePersonLength = devicePersonLength
                this.getDevicePersonList()
            },
            handleDeviceRecordSizeChange(deviceRecordLength) {
                this.deviceRecordLength = deviceRecordLength
                this.getDeviceRecordList()
            },
            handlePersonCurrentChange(personPage) {
                this.personPage = personPage
                this.getPersonList()
            },

            handleDevicePersonCurrentChange(devicePersonPage) {
                this.devicePersonPage = devicePersonPage
                this.getDevicePersonList()
            },

            handleDeviceRecordCurrentChange(deviceRecordPage) {
                this.deviceRecordPage = deviceRecordPage
                this.getDeviceRecordList()
            },
            handleCurrentChange(page) {
                this.page = page
                this.getDeviceList()
            },
            handleSelectionChange(selectDeviceArray) {
                this.selectDeviceArray = selectDeviceArray
            },
            handlePersonSelectionChange(selectPersonArray) {
                this.selectPersonArray = selectPersonArray
            },
            handlerSelectOnLineUnAddDevice(selectOnLineUnAddDeviceArray) {
                this.selectOnLineUnAddDeviceArray = selectOnLineUnAddDeviceArray
            },
            handleDevicePersonSelectionChange(selectDevicePersonArray) {
                this.selectDevicePersonArray = selectDevicePersonArray
            },
            handleDeviceRecordSelectionChange(selectDeviceRecordArray) {
                this.selectDeviceRecordArray = selectDeviceRecordArray
            },
            likeInput(value) {
                this.refieshDevice()
            },
            likeSnInput(value) {
                this.refieshDevice()
            },
            selecDeviceGroupChange(deviceGroupValue) {
                this.deviceData = []
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    if (deviceGroupValue == this.deviceGroupData[i].name) {
                        this.deviceGroupId = this.deviceGroupData[i].deviceGroupId
                        this.getDeviceList()
                        break
                    }
                }
            },

            updateSelecDeviceGroupChange(deviceGroupValue) {
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    if (deviceGroupValue == this.deviceGroupData[i].name) {
                        this.deviceGroupId = this.deviceGroupData[i].deviceGroupId
                        break
                    }
                }
            },
            addDeviceGroupChange(value) {
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    if (value == this.deviceGroupData[i].name) {
                        this.deviceGroupId = this.deviceGroupData[i].deviceGroupId
                        break
                    }
                }
            },
            updateDeviceGroupChange(value) {
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    if (value == this.deviceGroupData[i].name) {
                        this.deviceGroupId = this.deviceGroupData[i].deviceGroupId
                        break
                    }
                }
            },
            selecPersonGroupChange(value) {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (value == personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                        this.getPersonList()
                    }
                }
            },
            selecPersonGroupChange2(value) {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (value == personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                    }
                }
            },
            selecAddOnlineUnAddDeviceGroupChange(value) {
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    var deviceGroup = this.deviceGroupData[i]
                    if (value == deviceGroup.name) {
                        this.onLineUnAddDeviceGroupId = deviceGroup.id
                    }
                }
            },
            getDeviceGroupList() {
                setTimeout(() => {
                    axios.post('/doorDeviceGroup/pagingQuery', {
                        page: 1,
                        length: 100,
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.deviceGroupData = res.data.data.content
                            this.deviceGroupId = this.deviceGroupData[this.deviceGroupData.length - 1].id
                            this.deviceGroupValue = this.deviceGroupData[this.deviceGroupData.length - 1].name
                            this.getDeviceList()
                        }
                    })
                })
            },
            addDeviceGroup() {
                setTimeout((res) => {
                    axios.post('/doorDeviceGroup/add', {
                        name: this.dialogAddDeviceGroupName,
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                            this.refieshDevice()
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.data.msg,
                            })
                        }
                        this.getDeviceGroupList()
                        this.addDevieGroupDialogVisible = false
                    })
                })
            },
            deleteDeviceGroup() {
                this.$confirm('此操作将永久删除当前设备分组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                })
                    .then(() => {
                        setTimeout(() => {
                            axios.get('/doorDeviceGroup/delete?id=' + this.deviceGroupId)
                                .then((res) => {
                                    if (res.data.code == 1) {
                                        this.$message({
                                            type: 'success',
                                            message: res.data.msg,
                                        })
                                        this.refieshDevice()
                                    } else {
                                        this.$message({
                                            type: 'error',
                                            message: res.data.msg,
                                        })
                                    }
                                })
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            },
            updateDeviceGroup() {
                setTimeout(() => {
                    axios.post('/doorDeviceGroup/update', {
                        id: this.deviceGroupId,
                        name: this.dialogUpdateDeviceGroupName,
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                            this.updateDevieGroupDialogVisible = false
                            this.value =
                                this.dialogUpdateDeviceGroupName
                            this.refieshDevice()
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    })
                })
            },
            getDeviceList() {
                this.deviceData = []
                for (var i = 0; i < this.deviceGroupData.length; i++) {
                    if ((this.deviceGroupData[i].id = this.deviceGroupId)) {
                        this.dialogUpdateDeviceGroupName =
                            this.deviceGroupData[i].name
                    }
                }
                this.loading = true
                setTimeout(() => {
                    axios.post('/doorDevice/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        parameter: {
                            doorDeviceGroup: this.deviceGroupId,
                        },
                        likeParameter: {
                            name: this.likeModel,
                            serialNumber: this.likeSnModel
                        }
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code == 1) {
                            this.deviceData = res.data.data.content
                            this.deviceCount = res.data.data.totalElements
                        }
                    }).catch((error) => {
                        this.loading = false
                    })
                })
            },
            deleteDevice(id) {
                setTimeout(() => {
                    axios.get('/doorDevice/delete?id=' + id)
                        .then((res) => {
                            if (res.data.code == 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                                this.getDeviceList()
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.data.msg,
                                })
                            }
                        })
                })
            },

            getDeviceState(state) {
                if (state == 1) {
                    return '在线'
                } else {
                    return '离线'
                }
            },
            handleVoiceBroadcast(index, device) {
                this.$prompt('请输入文字', '语音播报', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then((data) => {
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.deviceData[index].serialNumber,
                            devicepass: this.deviceData[index].password,
                            tasktype: 65,
                            data: [data.value],
                        })
                            .then((res) => {
                                if (res.data.code == 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg,
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.data.msg,
                                    })
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'error',
                                    message: '请求服务器失败!',
                                })
                            })
                    })
                })
            },
            handleOpenBox(index, device) {
                this.$prompt('请输入箱号', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /[0-9><=]/,
                    inputErrorMessage: '箱号格式不正确'
                }).then(({value}) => {
                    this.confirmOpenBox(value, device)
                }).catch(() => {

                });

            },
            handleRestart(index, device) {
                this.$confirm(
                    '此操作将设备重启,是否继续?',
                    '重启(' + this.deviceData[index].name + ')', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                ).then(() => {
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.deviceData[index].serialNumber,
                            devicepass: this.deviceData[index].password,
                            tasktype: 4,
                            data: [true],
                        })
                            .then((res) => {
                                if (res.data.code == 1) {
                                    this.$message({
                                        type: 'success',
                                        message: '重启成功!',
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '重启失败!',
                                    })
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'error',
                                    message: '请求失败!',
                                })
                            })
                    })
                })
            },
            handleShutdown(index, device) {
                this.$confirm(
                    '此操作将设备关机,是否继续?',
                    '关机(' + this.deviceData[index].name + ')', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                ).then(() => {
                    setTimeout((res) => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.deviceData[index].serialNumber,
                            devicepass: this.deviceData[index].password,
                            tasktype: 4,
                            data: [false],
                        })
                            .then((res) => {
                                if (res.data.code == 1) {
                                    this.$message({
                                        type: 'success',
                                        message: '关机成功!',
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '失败!',
                                    })
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'error',
                                    message: '请求失败!',
                                })
                            })
                    })
                })
            },
            handleLook(index, device) {
                this.lookDeviceDialogVisible = true
                this.lookDevice = device
            },
            handleEdit(index, device) {
                this.updateDeviceDialogVisible = true
                var device = this.deviceData[index]
                this.dialogDeviceId = device.id
                this.dialogDeviceName = device.name
                this.dialogDeviceSerialNumber = device.serialNumber
                this.dialogDevicePassword = device.password
                this.dialogDeviceDescription = device.description
            },

            handleOther(index, device) {
                this.otherDeviceDialogVisible = true
                var device = this.deviceData[index]
                this.device = device
                this.getDeviceConfig(device)
            },
            addDevice() {
                setTimeout((res) => {
                    axios.post('/doorDevice/add', {
                        name: this.dialogDeviceName,
                        serialNumber: this.dialogDeviceSerialNumber,
                        password: this.dialogDevicePassword,
                        description: this.dialogDeviceDescription,
                        deviceGroupId: this.deviceGroupId,
                    })
                        .then((res) => {
                            if (res.data.code == 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.data.msg,
                                })
                            }
                            this.getDeviceList(
                                this.page,
                                this.deviceGroupId
                            )
                            this.addDeviceDialogVisible = false
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })
                })
            },
            updateDevice() {
                setTimeout(() => {
                    axios.post('/doorDevice/update', {
                        id: this.dialogDeviceId,
                        name: this.dialogDeviceName,
                        serialNumber: this.dialogDeviceSerialNumber,
                        password: this.dialogDevicePassword,
                        description: this.dialogDeviceDescription,
                        deviceGroupId: this.deviceGroupId,
                    })
                        .then((res) => {
                            if (res.data.code == 1) {
                                this.updateDeviceDialogVisible = false
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg,
                                })
                            }
                            this.getDeviceList(
                                this.page,
                                this.deviceGroupId
                            )
                            this.updateDeviceDialogVisible = false
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })
                })
            },
            deleteSelectDevice() {
                this.$confirm('此操作将永久删除当前选中设备, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    setTimeout(() => {
                        var deleteCount = 0
                        for (var i = 0; i < this.selectDeviceArray.length; i++) {
                            var device = this.selectDeviceArray[i]
                            axios.get('/doorDevice/delete?id=' + device.id)
                                .then((res) => {
                                    deleteCount += 1
                                    if (deleteCount >= this.selectDeviceArray.length) {
                                        loading.close()
                                        this.getDeviceGroupList()
                                    }
                                })
                                .catch((error) => {
                                    deleteCount += 1
                                    if (deleteCount >= this.selectDeviceArray.length) {
                                        loading.close()
                                        this.getDeviceGroupList()
                                    }
                                })
                        }
                    }, 500)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            },
            updateSelectDeviceToGroup() {
                this.selectUpdateDevieGroupDialogVisible = false
                setTimeout(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    var count = 0
                    for (var i = 0; i < this.selectDeviceArray.length; i++) {
                        var device = this.selectDeviceArray[i]
                        axios.post('/doorDevice/update', {
                            id: device.id,
                            deviceGroupId: this.deviceGroupId,
                            password: device.password
                        }).then((res) => {
                            count += 1
                            if (count >= this.selectDeviceArray.length) {
                                loading.close()
                                this.refieshDevice()
                                this.$message({
                                    type: 'success',
                                    message: '执行完成!',
                                })
                            }
                        })
                            .catch((res) => {
                                count += 1
                                if (count >= this.selectDeviceArray.length) {
                                    loading.close()
                                    this.refieshDevice()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                    }
                })
            },
            refieshDevice() {
                this.getDeviceGroupList()
            },
            selectDeviceReboot() {
                setTimeout((res) => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    var count = 0
                    for (var i = 0; i < this.selectDeviceArray.length; i++) {
                        var device = this.selectDeviceArray[i]
                        axios.post('/doorDevice/sendData', {
                            serialNumber: device.serialNumber,
                            devicepass: device.password,
                            tasktype: 4,
                            data: [true],
                        })
                            .then((res) => {
                                count += 1
                                if (count >= this.selectDeviceArray.length) {
                                    loading.close()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                            .catch((res) => {
                                count += 1
                                if (count >= this.selectDeviceArray.length) {
                                    loading.close()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                    }
                })
            },
            selectDeviceShutdown() {
                setTimeout((res) => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    var count = 0
                    for (var i = 0; i < this.selectDeviceArray.length; i++) {
                        var device = this.selectDeviceArray[i]
                        axios.post('/doorDevice/sendData', {
                            serialNumber: device.serialNumber,
                            devicepass: device.password,
                            tasktype: 4,
                            data: [false],
                        })
                            .then((res) => {
                                count += 1
                                if (count >= this.selectDeviceArray.length) {
                                    loading.close()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                            .catch((res) => {
                                count += 1
                                if (count >= this.selectDeviceArray.length) {
                                    loading.close()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                    }
                })
            },

            getDeviceConfig(device) {
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: device.serialNumber,
                        devicepass: device.password,
                        tasktype: 31,
                        data: [],
                    })
                        .then((res) => {
                            if (res.data.code == 1) {
                                this.config = res.data.data
                            }
                        })
                })
            },
            setDeviceConfig() {
                setTimeout((res) => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.device.serialNumber,
                        devicepass: this.device.password,
                        tasktype: 30,
                        data: this.config,
                    }).then((res) => {
                        this.otherDeviceDialogVisible = false
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '修改成功!',
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: '修改失败!',
                            })
                        }
                    })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                            this.otherDeviceDialogVisible = false
                        })
                })
            },
            openPlushPerson() {
                this.getPersonGroupList()
            },

            getPersonType(type) {
                if (type == 0) {
                    return '大众型'
                }
                if (type == 1) {
                    return '私有型'
                }
                if (type == 2) {
                    return '管理员'
                }
            },
            getPersonGroupList() {
                setTimeout(() => {
                    axios.post('/doorPersonGroup/pagingQuery', {
                        page: 1,
                        length: 100,
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.personGroupData = res.data.data.content
                            this.personGroupValue = this.personGroupData[this.personGroupData.length - 1].name
                            this.personGroupId = this.personGroupData[this.personGroupData.length - 1].personGroupId
                            this.getPersonList()
                        }
                    })
                })
            },
            getPersonList() {
                this.personData = []
                this.personLoading = true
                setTimeout(() => {
                    var parameter = {}
                    if (this.personGroupId !== 0) {
                        parameter = {
                            doorPersonGroup: this.personGroupId,
                        }
                    }
                    axios.post('/doorPerson/pagingQuery', {
                        page: this.personPage,
                        length: this.personLength,
                        base64: true,
                        parameter: parameter,
                    }).then((res) => {
                        this.personLoading = false
                        this.selectPersonPushDialogVisible = true
                        if (res.data.code == 1) {
                            this.personData = res.data.data.content
                            this.personCount = res.data.data.totalElements
                        }
                    })
                })
            },
            plushPerson() {
                const loading = this.$loading({
                    lock: true,
                    text: '正在下发,请稍后......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                this.percentage = 0
                var count = 0
                setTimeout(() => {
                    for (var a = 0; a < this.selectDeviceArray.length; a++) {
                        var device = this.selectDeviceArray[a]
                        for (
                            var b = 0; b < this.selectPersonArray.length; b++
                        ) {
                            var person = this.selectPersonArray[b]
                            axios.post('/doorDevice/sendData', {
                                serialNumber: device.serialNumber,
                                devicepass: device.password,
                                tasktype: 6,
                                data: {
                                    name: person.name,
                                    idcard: person.idCard,
                                    iccard: person.icCard,
                                    type: person.type,
                                    phone: person.phone,
                                    base64: person.base64,
                                    boxnum: person.boxNumber
                                },
                            }).then((res) => {
                                count += 1
                                this.percentage = Math.round((count / (this.selectPersonArray.length * this
                                    .selectDeviceArray.length)) * 100)
                                if (
                                    count >=
                                    this.selectPersonArray.length *
                                    this.selectDeviceArray.length
                                ) {
                                    this.$message({
                                        type: 'success',
                                        message: '下发执行完成!',
                                    })
                                    loading.close()
                                    this.selectPersonPushDialogVisible = false
                                }
                            })
                                .catch((error) => {
                                    count += 1
                                    this.percentage = Math.round(
                                        (count /
                                            (this.selectPersonArray.length *
                                                this.selectDeviceArray
                                                    .length)) *
                                        100
                                    )
                                    if (
                                        count >=
                                        this.selectPersonArray.length *
                                        this.selectDeviceArray.length
                                    ) {
                                        this.$message({
                                            type: 'success',
                                            message: '下发执行完成!',
                                        })
                                        loading.close()
                                        this.selectPersonPushDialogVisible = false
                                    }
                                })
                        }
                    }
                })
            },

            lookDevicePerson() {
                this.lookDeviceDialogVisible = false
                this.lookDevicePersonDialogVisible = true
                this.getDevicePersonList()
                this.getDeviceRuleList()
            },
            lookDeviceRecord() {
                this.lookDeviceDialogVisible = false
                this.lookDeviceRecordDialogVisible = true
                this.getDeviceRecordList()
            },
            lookDeviceRule() {
                this.lookDeviceDialogVisible = false
                this.lookDeviceRuleDialogVisible = true
                this.getDeviceRuleList()
            },
            lookBoxState() {
                this.getBoxData()
                this.lookDeviceDialogVisible = false
                this.lookBoxStateDialogVisible = true
            },
            getBoxData() {
                this.boxLoading = true
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 23,
                        data: [],
                    }).then((res) => {
                        this.boxLoading = false
                        if (res.data.code == 1) {
                            this.boxData = res.data.data
                            this.totality=0
                            this.haveBeenUsed = 0
                            this.unused = 0;
                            for (let i = 0; i < this.boxData.length; i++) {
                                var box = this.boxData[i]
                                if (box.state == 0) {
                                    this.unused += 1;
                                }
                                if (box.state == 1) {
                                    this.haveBeenUsed += 1;
                                }
                            }
                            this.totality=this.boxData.length
                        }
                    }).catch((error) => {
                        this.loading = false
                    })
                })
            },
            getDevicePersonList() {
                this.devicePersonData = []
                this.devicePersonLoading = true
                this.percentage = 0
                setTimeout(() => {
                    var offset = this.devicePersonPage * this.devicePersonLength - this.devicePersonLength
                    var limit = this.devicePersonLength
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 7,
                        data: [offset, limit, 0, 0],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            axios.post('/doorDevice/sendData', {
                                serialNumber: this.lookDevice.serialNumber,
                                devicepass: this.lookDevice.password,
                                tasktype: 63,
                                data: [],
                            }).then((res2) => {
                                this.devicePersonLoading = false
                                if (res2.data.code == 1) {
                                    this.devicePersonCount = res2.data.data
                                    this.devicePersonData = res.data.data
                                }
                            }).catch((error) => {
                                this.devicePersonLoading = false
                            })
                        } else {
                            this.devicePersonLoading = false
                        }
                    }).catch((error) => {
                        this.devicePersonLoading = false
                    })
                })
            },
            getDeviceRecordList() {
                this.deviceRecordData = []
                this.deviceRecordLoading = true
                this.percentage = 0
                setTimeout(() => {
                    var page = this.deviceRecordPage - 1
                    if (page <= 0) {
                        page = 0
                    }
                    var offset = page * this.deviceRecordLength
                    var limit = this.deviceRecordLength
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 15,
                        data: [offset, limit, 0, 164673349134610, -1],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            axios.post('/doorDevice/sendData', {
                                serialNumber: this.lookDevice.serialNumber,
                                devicepass: this.lookDevice.password,
                                tasktype: 64,
                                data: [],
                            }).then((res2) => {
                                this.deviceRecordLoading = false
                                if (res2.data.code == 1) {
                                    this.deviceRecordCount = res2.data.data
                                    this.deviceRecordData = res.data.data
                                }
                            }).catch((error) => {
                                this.deviceRecordLoading = false
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                            this.deviceRecordLoading = false
                        }
                    }).catch((error) => {
                        this.deviceRecordLoading = false
                    })
                })
            },
            getBase64(base64) {
                if (!base64) {
                    return ''
                }
                return 'data:image/png;base64,' + base64
            },
            handleClose(done) {
                this.getDeviceList()
                this.selectPersonArray = []
                this.selectDeviceArray = []
                this.selectDevicePersonArray = []
                this.devicePersonArray = []
                done()
            },
            getVerificationtype(type) {
                if (type == 0) {
                    return '人脸验证'
                } else if (type == 1) {
                    return '指纹验证'
                } else if (type == 2) {
                    return '密码验证'
                } else if (type == 3) {
                    return 'IC卡验证'
                } else if (type == 4) {
                    return '身份证验证'
                } else if (type == 5) {
                    return '微信二维码验证'
                } else if (type == 6) {
                    return '二维码验证'
                } else {
                    return '人脸验证'
                }
            },

            deleteSelectDevicePerson() {
                this.$confirm(
                    '此操作将永久删除当前选中设备人员, 是否继续?',
                    '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                    }
                ).then(() => {
                    setTimeout(() => {
                        const loading = this.$loading({
                            lock: true,
                            text: '正删除,请稍后......',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)',
                        })
                        var count = 0
                        this.percentage = 0
                        if (this.selectDevicePersonArray.length > 0) {
                            for (
                                var i = 0; i < this.selectDevicePersonArray.length; i++
                            ) {
                                var person = this.selectDevicePersonArray[i]
                                axios.post('/doorDevice/sendData', {
                                    serialNumber: this.lookDevice.serialNumber,
                                    devicepass: this.lookDevice.password,
                                    tasktype: 12,
                                    data: [person.id],
                                }).then((res) => {
                                    count += 1
                                    this.percentage = Math.round(
                                        (count / this.selectDevicePersonArray.length) * 100)
                                    if (count >= this.selectDevicePersonArray.length) {
                                        loading.close()
                                        this.getDevicePersonList()
                                    }
                                }).catch((error) => {
                                    count += 1
                                    if (
                                        count >=
                                        this.selectDevicePersonArray.length
                                    ) {
                                        loading.close()
                                        this.getDevicePersonList()
                                    }
                                })
                            }
                        }
                    })
                })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            },
            selectPersonAddToGroup() {
                this.selectPersonAddToGroupDialogVisible = false
                setTimeout(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在添加,请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    var count = 0
                    this.percentage = 0
                    if (this.selectDevicePersonArray.length > 0) {
                        for (var i = 0; i < this.selectDevicePersonArray.length; i++) {
                            var person = this.selectDevicePersonArray[i]
                            axios.post('/doorPerson/addDevicePerson', {
                                name: person.name,
                                idCard: person.idcard,
                                icCard: person.iccard,
                                phone: person.phone,
                                boxNumber: person.boxnum,
                                base64: person.base64,
                                type: person.type,
                                personGroupId: this.personGroupId,
                            })
                                .then((res) => {
                                    count += 1
                                    this.percentage = Math.round((count / this.selectDevicePersonArray
                                        .length) * 100)
                                    if (count >= this.selectDevicePersonArray.length) {
                                        loading.close()
                                        this.getDevicePersonList()
                                        this.$message({
                                            type: 'success',
                                            message: '执行完成！',
                                        })
                                        this.selectPersonAddToGroupDialogVisible = false
                                        this.$router.replace('/door_person')
                                    }
                                })
                                .catch((error) => {
                                    count += 1
                                    if (count >= this.selectDevicePersonArray.length) {
                                        loading.close()
                                        this.getDevicePersonList()
                                        this.$message({
                                            type: 'success',
                                            message: '执行完成！',
                                        })
                                        this.selectPersonAddToGroupDialogVisible = false
                                        this.$router.replace('/door_person')
                                    }
                                })
                        }
                    }
                })
            },
            addSelectDevicePerson() {
                setTimeout(() => {
                    axios.post('/doorPersonGroup/pagingQuery', {
                        page: 1,
                        length: 100,
                    })
                        .then((res) => {
                            if (res.data.code == 1) {
                                this.selectPersonAddToGroupDialogVisible = true
                                this.personGroupData = res.data.data.content
                                this.personGroupValue = this.personGroupData[this.personGroupData.length - 1].name
                                this.personGroupId = this.personGroupData[this.personGroupData.length - 1].personGroupId
                            }
                        })
                })
            },
            deleteSelectDeviceRecord() {
                this.$confirm(
                    '此操作将永久删除当前选中设备记录, 是否继续?',
                    '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                    }
                )
                    .then(() => {
                        setTimeout(() => {
                            const loading = this.$loading({
                                lock: true,
                                text: '正删除,请稍后......',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)',
                            })
                            var count = 0
                            this.percentage = 0
                            if (this.selectDeviceRecordArray.length > 0) {
                                for (var i = 0; i < this.selectDeviceRecordArray.length; i++) {
                                    var record = this.selectDeviceRecordArray[i]
                                    axios.post('/doorDevice/sendData', {
                                        serialNumber: this.lookDevice.serialNumber,
                                        devicepass: this.lookDevice.password,
                                        tasktype: 20,
                                        data: [record.id],
                                    })
                                        .then((res) => {
                                            count += 1
                                            this.percentage = Math.round((count / this
                                                .selectDeviceRecordArray.length) * 100)
                                            if (count >= this.selectDeviceRecordArray.length) {
                                                loading.close()
                                                this.getDeviceRecordList()
                                                this.$message({
                                                    type: 'success',
                                                    message: '执行完成！',
                                                })
                                            }
                                        })
                                        .catch((error) => {
                                            count += 1
                                            if (count >= this.selectDeviceRecordArray.length) {
                                                loading.close()
                                                this.getDeviceRecordList()
                                                this.$message({
                                                    type: 'success',
                                                    message: '执行完成！',
                                                })
                                            }
                                        })
                                }
                            }
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })

                    })
            },
            updateDeviceSinglePerson(index, person) {
                this.devicePersonArray = [person.id,
                    person.name,
                    person.idcard,
                    person.iccard,
                    person.phone,
                    person.type,
                    person.boxnum,
                    person.base64,
                ]
                this.selectPersonRule = []
                let rule = []
                if (person.rule == undefined) {

                } else {
                    rule = JSON.parse(person.rule)
                }
                for (let i = 0; i < this.personRuleData.length; i++) {
                    for (let j = 0; j < rule.length; j++) {
                        if (this.personRuleData[i].id == rule[j]) {
                            this.selectPersonRule.push(this.personRuleData[i].name)
                        }
                    }
                }
                this.dialogPersonFileUrl = this.getBase64(person.base64)
                this.updatePersonDialogVisible = true
                this.getDeviceRuleList()
            },
            deleteDeviceSinglePerson(index, person) {
                this.$confirm(
                    '此操作将永久删除当前这条设备人员，是否继续?',
                    '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                    }
                )
                    .then(() => {
                        setTimeout(() => {
                            const loading = this.$loading({
                                lock: true,
                                text: '正删除,请稍后......',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)',
                            })
                            axios.post('/doorDevice/sendData', {
                                serialNumber: this.lookDevice.serialNumber,
                                devicepass: this.lookDevice.password,
                                tasktype: 12,
                                data: [person.id],
                            })
                                .then((res) => {
                                    loading.close()
                                    this.getDevicePersonList()
                                    if (res.data.code == 1) {
                                        this.$message({
                                            type: 'success',
                                            message: res.data.msg,
                                        })
                                    } else {
                                        this.$message({
                                            type: 'error',
                                            message: res.data.msg,
                                        })
                                    }
                                })
                                .catch((error) => {
                                    loading.close()
                                })
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })

            },
            deleteDeviceSingleRecord(index, record) {
                this.$confirm(
                    '此操作将永久删除当前这条设备记录，是否继续?',
                    '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error',
                    }
                ).then(() => {
                    setTimeout(() => {
                        const loading = this.$loading({
                            lock: true,
                            text: '正删除,请稍后......',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)',
                        })
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.lookDevice.serialNumber,
                            devicepass: this.lookDevice.password,
                            tasktype: 20,
                            data: [record.id],
                        })
                            .then((res) => {
                                loading.close()
                                this.getDeviceRecordList()
                                if (res.data.code == 1) {
                                    this.$message({
                                        type: 'success',
                                        message: '删除成功!',
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '删除失败!',
                                    })
                                }
                            })
                            .catch((error) => {
                                loading.close()
                                this.$message({
                                    type: 'error',
                                    message: '请求服务器失败!',
                                })
                            })
                    })
                })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },
            // 时间戳转时间
            transformTime(timestamp) {
                var date = new Date(timestamp);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            },
            handleAvatarSuccess(res, file) {
                if (res.code == 1) {
                    this.dialogPersonFileName = res.data.fileName
                    this.dialogPersonFileUrl = URL.createObjectURL(file.raw);
                    this.devicePersonArray[7] = res.data.base64
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            updatePerson() {
                setTimeout(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })

                    let ruleIdArray = []
                    if (this.selectPersonRule.length > 0) {
                        if (this.personRuleData.length > 0) {
                            for (let i = 0; i < this.personRuleData.length; i++) {
                                for (let j = 0; j < this.selectPersonRule.length; j++) {
                                    if (this.personRuleData[i].name == this.selectPersonRule[j]) {
                                        ruleIdArray.push(this.personRuleData[i].id)
                                    }
                                }
                            }
                        }
                    }
                    var updatePerson = {
                        id: this.devicePersonArray[0],
                        name: this.devicePersonArray[1],
                        idcard: this.devicePersonArray[2],
                        iccard: this.devicePersonArray[3],
                        phone: this.devicePersonArray[4],
                        type: this.devicePersonArray[5],
                        boxnum: this.devicePersonArray[6],
                        base64: this.devicePersonArray[7],
                        rule: ruleIdArray
                    }
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 14,
                        data: updatePerson,
                    }).then((res) => {
                        this.updatePersonDialogVisible = false
                        loading.close()
                        if (res.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                            this.getDevicePersonList()
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.updatePersonDialogVisible = false
                        loading.close()
                    })
                })

            },
            getBoxType(type) {
                if (type == 0) {
                    return '小箱'
                }
                if (type == 1) {
                    return '中箱'
                }
                if (type == 2) {
                    return '大箱'
                }
            }
            , boxOnItem(box) {
                this.$message({
                    type: 'success',
                    message: box.boxNumber,
                })

            },
            getBoxState(state) {
                if (state == 0) {
                    return '未占用'
                }
                if (state == 1) {
                    return '已占箱'
                }

                if (state == 2) {
                    return '已锁定'
                }
            },

            dialogOpenBox(index, box) {
                this.confirmOpenBox(box.boxNumber, this.lookDevice)
            },
            dialogClearBox(index, box) {
                this.$prompt('请输入清箱备注', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputErrorMessage: '清箱备注不能为空',
                    inputValidator: (value => {
                        if (!value) {
                            return '清箱备注不能为空'
                        }
                    })
                }).then(({value}) => {
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.lookDevice.serialNumber,
                            devicepass: this.lookDevice.password,
                            tasktype: 46,
                            data: [box.boxNumber],
                        }).then((res) => {
                            if (res.data.code == 1) {
                                this.getBoxData()
                                axios.post('/doorOpenBoxRecord/add', {
                                    remark: value,
                                    boxNumber: '清' + box.boxNumber + '号箱',
                                    deviceName: this.lookDevice.name,
                                    serialNumber: this.lookDevice.serialNumber
                                })
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '清箱失败!',
                                })
                            }
                        }).catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })

                    })
                }).catch(() => {

                });
            },
            dialogLockBox(index, box) {
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 26,
                        data: [box.boxNumber],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.getBoxData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })
                })
            },
            dialogUnLockBox(index, box) {
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 27,
                        data: [box.boxNumber],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.getBoxData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })
                })
            },
            dialogUpdateRemarkBox(index, box) {
                this.$prompt('请输入箱子备注', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({value}) => {
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.lookDevice.serialNumber,
                            devicepass: this.lookDevice.password,
                            tasktype: 53,
                            data: [box.boxNumber, value],
                        }).then((res) => {
                            if (res.data.code == 1) {
                                this.getBoxData()
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg,
                                })
                            }
                        }).catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })
                    })
                }).catch(() => {
                });

            },
            dialogModifyTheBoundCardNumber(index, box) {
                this.$prompt('请输入箱子绑定卡号', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({value}) => {
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.lookDevice.serialNumber,
                            devicepass: this.lookDevice.password,
                            tasktype: 66,
                            data: [box.boxNumber, value],
                        }).then((res) => {
                            if (res.data.code == 1) {
                                this.getBoxData()
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg,
                                })
                            }
                        }).catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })
                    })
                }).catch(() => {
                });

            },
            allOpenBox() {
                this.$confirm('此操作将打开该设备所有箱子, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$prompt('请输入开箱备注', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputErrorMessage: '开箱备注不能为空',
                        inputValidator: (value => {
                            if (!value) {
                                return '开箱备注不能为空'
                            }
                        })
                    }).then(({value}) => {
                        setTimeout(() => {
                            axios.post('/doorDevice/sendData', {
                                serialNumber: this.lookDevice.serialNumber,
                                devicepass: this.lookDevice.password,
                                tasktype: 48,
                                data: [],
                            }).then((res) => {
                                if (res.data.code == 1) {
                                    axios.post('/doorOpenBoxRecord/add', {
                                        remark: value,
                                        boxNumber: '全开',
                                        deviceName: this.lookDevice.name,
                                        serialNumber: this.lookDevice.serialNumber
                                    }).then((res1) => {
                                    })
                                    this.getBoxData()
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg,
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.data.msg,
                                    })
                                }
                            })
                        })
                    }).catch(() => {

                    });
                }).catch(() => {

                });
            },
            allClearBox() {
                this.$confirm('此操作将清空该设备所有箱子, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$prompt('请输入清箱备注', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputErrorMessage: '清箱备注不能为空',
                        inputValidator: (value => {
                            if (!value) {
                                return '清箱备注不能为空'
                            }
                        })
                    }).then(({value}) => {
                        setTimeout(() => {
                            axios.post('/doorDevice/sendData', {
                                serialNumber: this.lookDevice.serialNumber,
                                devicepass: this.lookDevice.password,
                                tasktype: 25,
                                data: [],
                            }).then((res) => {
                                if (res.data.code == 1) {
                                    this.getBoxData()
                                    axios.post('/doorOpenBoxRecord/add', {
                                        remark: value,
                                        boxNumber: "全清",
                                        deviceName: this.lookDevice.name,
                                        serialNumber: this.lookDevice.serialNumber
                                    })
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg,
                                    })
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: res.data.msg,
                                    })
                                }
                            }).catch((error) => {
                                this.$message({
                                    type: 'error',
                                    message: '请求失败!',
                                })
                            })

                        })
                    }).catch(() => {

                    });

                }).catch(() => {

                });
            },
            allLockBox() {
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 67,
                        data: [],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.getBoxData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })

                })
            },
            allUnLockBox() {
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 68,
                        data: [],
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.getBoxData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })

                })
            },
            confirmOpenBox(boxNumber, device) {
                this.$prompt('请输入开箱备注', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputErrorMessage: '开箱备注不能为空',
                    inputValidator: (value => {
                        if (!value) {
                            return '开箱备注不能为空'
                        }
                    })
                }).then(({value}) => {
                    this.openBox(value, boxNumber, device)
                }).catch(() => {
                });
            },
            openBox(value, boxNumber, device) {
                axios.post('/doorDevice/sendData', {
                    serialNumber: device.serialNumber,
                    devicepass: device.password,
                    tasktype: 24,
                    data: [boxNumber <= 0 ? 1 : boxNumber],
                }).then((res) => {
                    if (res.data.code == 1) {
                        axios.post('/doorOpenBoxRecord/add', {
                            remark: value,
                            boxNumber: '开' + boxNumber + '号箱',
                            deviceName: device.name,
                            serialNumber: device.serialNumber
                        })
                        this.$message({
                            type: 'success',
                            message: res.data.msg,
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '开箱失败!',
                        })
                    }
                }).catch((error) => {
                    this.$message({
                        type: 'error',
                        message: '请求失败!',
                    })
                })
            },
            addDeviceRule() {
                this.addDeviceRuleDialogVisible = true
            },
            getDeviceRuleList() {
                this.deviceRuleData = []
                this.personRuleData = []
                this.deviceRuleLoading = true
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 38,
                        data: [],
                    }).then((res) => {
                        this.deviceRuleLoading = false
                        if (res.data.code == 1) {
                            this.deviceRuleData = res.data.data
                            for (let i = 0; i < this.deviceRuleData.length; i++) {
                                if (this.deviceRuleData[i].type == 1) {
                                    this.personRuleData.push(this.deviceRuleData[i])
                                }
                            }
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.deviceRuleLoading = false
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })
                })
            },
            getDeviceRuleType(type) {
                if (type == 0) {
                    return '设备规则'
                } else {
                    return '人员规则'
                }
            },
            dialogEditDeviceRule(index, rule) {
                this.editDeviceRule = rule
                this.editDeviceRuleDialogVisible = true
                let startTimeArrays = rule.startTime.split(':')
                let endTimeArrays = rule.endTime.split(':')
                let date = new Date();
                this.editDialogRuleStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDay(), startTimeArrays[0], startTimeArrays[1], 0)
                this.editDialogRuleEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDay(), endTimeArrays[0], endTimeArrays[1], 0)
                this.editCheckRuleWeekList = this.resolveArraysWeek(rule.week)
                this.editDeviceRuleRadio = rule.type
                this.editDeviceRuleName = rule.name

            },
            dialogDeleteDeviceRule(index, rule) {
                this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deviceRuleLoading = true
                    setTimeout(() => {
                        axios.post('/doorDevice/sendData', {
                            serialNumber: this.lookDevice.serialNumber,
                            devicepass: this.lookDevice.password,
                            tasktype: 39,
                            data: [rule.id],
                        }).then((res) => {
                            this.deviceRuleLoading = false
                            if (res.data.code == 1) {
                                this.getDeviceRuleList()
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg,
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg,
                                })
                            }
                        }).catch((error) => {
                            this.deviceRuleLoading = false
                            this.$message({
                                type: 'error',
                                message: '请求失败!',
                            })
                        })
                    })
                }).catch(() => {

                });

            },
            handleDeviceRuleChecked(vales) {

            },
            handleEditDeviceRuleChecked(value) {

            },
            saveDeviceRule() {
                if (this.deviceRuleName == undefined || this.deviceRuleName == '' || this.deviceRuleName.length <= 0) {
                    this.$message({
                        type: 'error',
                        message: '规则名称不能为空！',
                    })
                    return
                }
                this.deviceRuleLoading = true
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 37,
                        data: {
                            name: this.deviceRuleName,
                            type: this.deviceRuleRadio,
                            week: this.resolveWeekArrays(this.checkRuleWeekList),
                            startTime: this.getDeviceRuleStartTime(),
                            endTime: this.getDeviceRuleEndTime()
                        },
                    }).then((res) => {
                        this.deviceRuleLoading = false
                        this.addDeviceRuleDialogVisible = false
                        if (res.data.code == 1) {
                            this.getDeviceRuleList()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.deviceRuleLoading = false
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })
                })
            },
            editSaveDeviceRule() {
                if (this.editDeviceRuleName == undefined || this.editDeviceRuleName == '' || this.editDeviceRuleName.length <= 0) {
                    this.$message({
                        type: 'error',
                        message: '规则名称不能为空！',
                    })
                    return
                }
                setTimeout(() => {
                    axios.post('/doorDevice/sendData', {
                        serialNumber: this.lookDevice.serialNumber,
                        devicepass: this.lookDevice.password,
                        tasktype: 41,
                        data: {
                            id: this.editDeviceRule.id,
                            name: this.editDeviceRuleName,
                            type: this.editDeviceRuleRadio,
                            week: this.resolveWeekArrays(this.editCheckRuleWeekList),
                            startTime: this.getEditDeviceRuleStartTime(),
                            endTime: this.getEditDeviceRuleEndTime()
                        },
                    }).then((res) => {
                        if (res.data.code == 1) {
                            this.editDeviceRuleDialogVisible = false
                            this.getDeviceRuleList()
                            this.$message({
                                type: 'success',
                                message: res.data.msg,
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg,
                            })
                        }
                    }).catch((error) => {
                        this.editDeviceRuleDialogVisible = false
                        this.$message({
                            type: 'error',
                            message: '请求失败!',
                        })
                    })
                })
            },

            resolveWeekArrays(weekArrays) {
                let week = [false, false, false, false, false, false, false]
                for (let i = 0; i < week.length; i++) {
                    for (let j = 0; j < weekArrays.length; j++) {
                        if (i == 0) {
                            if (weekArrays[j] == '星期天') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 1) {
                            if (weekArrays[j] == '星期一') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 2) {
                            if (weekArrays[j] == '星期二') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 3) {
                            if (weekArrays[j] == '星期三') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 4) {
                            if (weekArrays[j] == '星期四') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 5) {
                            if (weekArrays[j] == '星期五') {
                                week[i] = true
                            }
                            continue
                        }
                        if (i == 6) {
                            if (weekArrays[j] == '星期六') {
                                week[i] = true
                            }
                            continue
                        }
                    }
                }
                return week
            },
            resolveArraysWeek(weeks) {
                let weekArrays = []
                let week = JSON.parse(weeks)
                for (let i = 0; i < week.length; i++) {
                    if (week[i] == true) {
                        if (i == 0) {
                            weekArrays.push('星期天')
                        }
                        if (i == 1) {
                            weekArrays.push('星期一')
                        }
                        if (i == 2) {
                            weekArrays.push('星期二')
                        }
                        if (i == 3) {
                            weekArrays.push('星期三')
                        }
                        if (i == 4) {
                            weekArrays.push('星期四')
                        }
                        if (i == 5) {
                            weekArrays.push('星期五')
                        }
                        if (i == 6) {
                            weekArrays.push('星期六')
                        }
                    }
                }
                return weekArrays
            },
            getDeviceRuleStartTime() {
                let hour = this.dialogRuleStartTime.getHours()
                let minute = this.dialogRuleStartTime.getMinutes()
                let hours = hour < 10 ? '0' + hour : hour + ''
                let minutes = hour < 10 ? '0' + minute : minute + ''
                return hours + ':' + minutes
            },
            getDeviceRuleEndTime() {
                let hour = this.dialogRuleEndTime.getHours()
                let minute = this.dialogRuleEndTime.getMinutes()
                let hours = hour < 10 ? '0' + hour : hour + ''
                let minutes = hour < 10 ? '0' + minute : minute + ''
                return hours + ':' + minutes
            },
            getEditDeviceRuleStartTime() {
                let hour = this.editDialogRuleStartTime.getHours()
                let minute = this.editDialogRuleStartTime.getMinutes()
                let hours = hour < 10 ? '0' + hour : hour + ''
                let minutes = hour < 10 ? '0' + minute : minute + ''
                return hours + ':' + minutes
            },
            getEditDeviceRuleEndTime() {
                let hour = this.editDialogRuleEndTime.getHours()
                let minute = this.editDialogRuleEndTime.getMinutes()
                let hours = hour < 10 ? '0' + hour : hour + ''
                let minutes = hour < 10 ? '0' + minute : minute + ''
                return hours + ':' + minutes
            },
            handlerSelectPersonRule(vales) {
                console.log('选择规则:' + JSON.stringify(this.selectPersonRule))
            }
        },
    }
</script>

<style>
    .door_device-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_deviec-tabler {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }

    .door_device-data-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        margin-bottom: 10px;
    }


</style>
